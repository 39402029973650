let cardapioPage = (function () {
    let cardapio = {};
    cardapio.init = function () {
        //Content

        //Accordion
        let itemClick = $(".item");
        let itemDataClick = $(".item-data");
        let navHeight = 80;
        accordion.initialize(itemClick, itemDataClick, navHeight);

        //Debug
        console.log("cardapio");
    };
    return cardapio;
})();

window.cardapioPage = cardapioPage;