/* Default */
require("gmartha07/default/js/jquery");
require("gmartha07/components/loading/base-loading");
require("gmartha07/default/js/axios");
require("gmartha07/default/js/axios-functions");
require("gmartha07/components/modernizr/modernizr");
require("gmartha07/components/accordion/js/accordion");

/* Bootstrap */
// require("popper.js");
// require("bootstrap/dist/js/bootstrap");

/* Plugins */
require("overlayscrollbars/js/jquery.overlayScrollbars");
import lozad from "lozad";
import Swiper, {Pagination} from "swiper";

window.lozad = lozad;
window.Swiper = Swiper;
Swiper.use([Pagination]);

/* Pages */
require("./pages/cardapio");
require("./pages/directory");
