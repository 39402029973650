let directoryPage = (function () {
    let directory = {};
    directory.init = function () {
        //Content

        //Accordion
        let item = $(".item");
        let itemData = $(".item-data");
        let navHeight = 10;
        accordion.initialize(item, itemData, navHeight);

        //Debug
        console.log("directory");
    };
    return directory;
})();

window.directoryPage = directoryPage;